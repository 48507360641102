@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?958rrb');
  src:  url('./assets/fonts/icomoon.eot?958rrb#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?958rrb') format('truetype'),
    url('./assets/fonts/icomoon.woff?958rrb') format('woff'),
    url('./assets/fonts/icomoon.svg?958rrb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-chevron-down:before {
  content: "\e900";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}




body {
  margin:0;
  background-color: #27272b;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
html, body, #root, .App, .content{
  height:100%;
  min-height: 100%;
}
.App{
  display: flex;
  flex-direction: column;
}
button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
a{
  color: #f1c40f;
}
hr{
  background-color:#fff;
  color: #fff
}

.transition-all{
  transition-property: all!important;
  transition-timing-function: cubic-bezier(.4,0,.2,1)!important;
  transition-duration: 0.2s!important;
}
.flex{
  display:flex;
}
.grow{
  flex:1 1;
}
.center{
  margin:0 auto;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}

.content{
  text-align:center;
  padding: 0 16px;
  margin:0 auto;
  width:100%;
  max-width: 1024px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.invalid{
    color: #cd3232;
}