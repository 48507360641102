.header{
    background-color:#3f3f45;
    display: flex;
    align-items: center;
}
.home-link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}
.logo{
    margin: 8px 0;
    height:64px;
}
.spacer{
    flex-grow:1;
}
.login{
    padding:12px;
    margin: 0 8px;
    border-radius: 50vh;
    background-color: #7289da;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.login:hover{
    background-color: #5a6dad;
}
.user-dropdown{
    margin: 8px;
    display: flex;
    align-items: center;
}
.user-pfp{
    height:48px;
    border-radius: 50vh;
    margin:4px;
}
.dropdown-menu{
    position: absolute;
    right:0;
    top:80px;
    padding: 0 16px;
    background-color: #2c2c33;
    z-index: 1000;
    border: 1px #aaa solid;
    text-align: center;
    box-sizing: border-box;
}
.dropdown-menu hr{
    margin:0;
    color:#aaa;
}
.dropdown-item{
    box-sizing: border-box;
    color:#aaa !important;
    display:block;
    padding:16px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}