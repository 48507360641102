.profile-container{
  display:flex;
  justify-content: center;
  line-height: 1.5em;
}
.profile-avatar{
    width:20%;
    height:20%;
    border-radius:50%;
    display:inline-block;
    margin:1em;
}
.stonks-container{
    width:100%;
    max-width: 720px;
}
.stonks-canvas{
    background-color: #000;
    width:100%;
}