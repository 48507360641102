.home-wrapper{
    width:100%;
    margin: 0 auto 3em;
    max-width: 512px;
    display: flex;
    flex-direction: column;
}
.home-wrapper a{
    font-size: 1.5em;;
}
.home-wrapper p{
    text-align: left;
}



.carousel-wrapper{
    width:100%;
    margin: 1em auto 3em;
}
.carousel-header{
    font-size: 1.5em;
}
.carousel-image {
    width: 100%;
}
.slick-dots li button:before {
    color: white !important;
}
  