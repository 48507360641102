/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 16px;
    margin: 1em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .switch-slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: -16px;
    top: -8px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius:50%;
  }
  
  input:checked + .switch-slider:before {
    background-color: #7289da;
  }
  
  input:checked + .switch-slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }
  