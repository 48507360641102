.guild-banner{
    width:128px;
    border-radius:50%;
    display:inline-block;
    margin:0.5em;
}
.dashboard-item{
    background-color: #3f3f45;
    border-radius: 1em;
    padding:1em;
    margin: 1em 0 2em;
}
.dashboard-item-header{
    text-decoration: underline;
    display:inline-block;
    margin: 1em 0;
}
.dashboard-form{
    margin:0 1em;
}
.dashboard-form-label{
    margin: 0 0 0.5em;
    display: block;
}
#welcome-text{
    padding:1em;
    resize: none;
    display: block;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.dashboard-select{
    min-width: 160px;
    height:25px;
    margin: 0 0 1em;
}
#welcome-image{
    margin: 0 0 1.5em;
    padding: 12px;
    display: block;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.dashboard-item-submit{
    margin:1.5em 0;
    min-width:100px;
    padding:12px;
    border-radius: 50vh;
    background-color: #7289da;
    color: #fff !important;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
}
.dashboard-message{
    text-align: center;
}
.dashboard-success{
    color: limegreen;
    text-align: center;
}
.dashboard-error{
    color: #cd3232;
    text-align: center;
}

.role-menu{
    border-radius: 0.5em;
    padding:0.75em;
    margin:0 0 1em;
    background-color: #5f5f68;
}
.add-menu{
    font-size:1.5em;
    padding:0.5em;
    border-radius: 0.5em;
    background-color: #5f5f68;
    cursor: pointer;
}
.role-menu-row{
    margin: 0 0 0.75em;
}
.add-menu-wrapper{
    padding:0.75em 0;
}
.remove-menu{
    font-size:1.5em;
    padding:0.5em;
    cursor: pointer;
}
.role-menu-select{
    min-width: 160px;
    height:25px;
}
.role-label{
    margin: 0 0.5em;
}
.role-select{
    height:25px;
    margin: 0 0.5em;
}
.add-role{
    font-size:1.5em;
    padding:0.5em;
    cursor: pointer;
}
.emoji-input{
    width:110px;
    margin: 0 0.5em;
}
.role-menu-button{
    margin: 0.75em 0;
}
.role-menu-button-item{
    margin: 0 0 0.5em;
    align-items: center;
}
.role-menu-circle, .role-menu-button-error{
    padding:0 0.5em;

}
.role-menu-button-error{
    color: #cd3232
}
.role-menu-message{
    text-align: center;
}
.remove-button{
    font-size:1.5em;
    padding:0.5em;
    cursor: pointer;
}
.role-message-label{
    margin: 0.5em 0;
    display: block;
}
.role-menu-message{
    text-align: left;
    padding:1em;
    resize: none;
    display: block;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}