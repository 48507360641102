/* MultiRangeSlider.css */
.multi-range-slider {
    position: relative;
  }
  
  .thumb {
    position: relative;
    z-index: 5;
    appearance: none;
    width: 100%;
    background: transparent;
    cursor: pointer;
    margin:0;
    border:0;
    padding:0;
  }
  
  .thumb::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: limegreen;
    cursor: pointer;
  }
  
  .slider-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
    top:14px;
    background-color: transparent;
  }
  
  .slider__track {
    position: absolute;
    width: 100%;
    height: 10px;
    background: grey;
  }
  
  .slider__range {
    position: absolute;
    height: 10px;
    background: limegreen;
  }
  
  .slider__left-value,
  .slider__right-value {
    position: absolute;
    top: 20px;
    font-size: 12px;
  }
  .slider__left-value {
    left: 0;
  }
  .slider__right-value {
    right: 0;
  }
  